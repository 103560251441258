import React, {Fragment, Component} from 'react';
import './App.css';

const APP_URL = '/wp-content/plugins/winvest-registration/js/'
const ERROR = {INVALID_EMAIL: "Adresa de e-mail este invalida.", UNDEFINED: "Ups. Ceva nu a mers. Te rugam sa reincarci pagina si sa incerci din nou.", SHORT_NAME: "Te rugam sa completezi numele intreg.", INVALID_PHONE: "Numarul de telefon este invalid."}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      name: '',
      email: '',
      phone: '',
      error: '',
      success: false
      
    }
/*
    console.log("Winvest registration form has loaded.");
    console.log("Form props loaded: ");
    console.log(this.props);
*/
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }


  async fetchData(endpoint = '', data = {}) {
        
      let url = APP_URL + endpoint;

      this.setState({
          error: ''
      });
/*
      console.log("Submitted data: ");
      console.log(data);
*/
      const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      .then(response => {

          return response.json();          

      });
      
      // console.log(response);

      if(response.success === true) {
        console.log("Fetch successfuly...");
//        console.log(response);
        this.setState({
          isLoading: false,
          success: true
        }, () => {
          setTimeout(() => {
            window.location = '/' + '?utm=thank-you' + '&' + 'position=' + this.props.position
          }, 12000);
        });
      }else{
        console.log("An error occured...");
        
        console.log(14);
//        console.log(response);

        this.setState({
          isLoading: false,
          error: ERROR.hasOwnProperty(response.error) ? ERROR[response.error]:response.error
        }, () => {

          setTimeout( () => {
            this.setState({
              error: ''
            })
          }, 3000);

        })
      }
  }

  handleChange(e) {
    const {name, value} = e.target  

    this.setState({
        [name]: value
    })
  }

  handleSubmit(e) {
    e.preventDefault();

    console.log("Form has been submitted.");

    if(this.state.name.length < 3) {
      this.setState({
        error: ERROR['SHORT_NAME']
      }, () => {
        setTimeout(() => {
          this.setState({
            error: ''
          });
        }, 4000);
      });

      return null;
    }

    if(this.state.phone.length < 9) {
      this.setState({
        error: ERROR['INVALID_PHONE']
      }, () => {
        setTimeout(() => {
          this.setState({
            error: ''
          });
        }, 4000);
      });

      return null;
    }

    let data = {
      "properties":{
         "email":this.state.email,
         "firstname":this.state.name,
         "phone": this.state.phone,
         "lifecyclestage": "lead",
         "hs_lead_status": "NEW",
         "utm_source": this.props.source,
         "utm_medium": this.props.medium,
         "utm_campaign": this.props.campaign,
         "utm_term": this.props.term,
         "utm_content": this.props.content
      }
    };

    this.setState({
      isLoading: true
    }, () => {
      this.fetchData('process.php', data);
    })

  }

  render() {

      if(this.state.success) {
        return (
          <div className="winvest-registration-form text-center pt-5">
            <div className="col-md-12"></div>
            <div className="col-md-12"></div>
            <div className="col-md-12">
              <div className="alert alert-success">
                <p>Te-ai inregistrat cu succes. Vei fi contactat de unul din reprezentantii Winvest pentru confirmare.</p>
                <hr />
                <p>In cateva momente vei fi redirectionat catre pagina noastra principala unde poti afla mai multe despre serviciile noastre.</p>
              </div>
            </div>
            <div className="col-md-12"></div>
            <div className="col-md-12"></div>
          </div>
        )
      }

      return (
      <Fragment>
        <div className="winvest-registration-form text-center pt-5">
          <div className="form-group">
            <div className="col-md-12">
              <input onChange={this.handleChange} type="text" className="wrf-input" name="name" placeholder="Nume Prenume" aria-invalid="false" />
            </div>
          </div>

          <div className="form-group">
            <div className="col-md-12">
              <input onChange={this.handleChange} type="text" className="wrf-input" name="email" placeholder="Email" aria-invalid="false" />
            </div>
          </div>

          <div className="form-group">
            <div className="col-md-12">
              <input onChange={this.handleChange} type="tel" className="wrf-input" name="phone" placeholder="Telefon" aria-invalid="false" />
            </div>
          </div>

          <div className="form-group">
            {this.state.error != '' ?
              <div className="col-md-12">
                <p className="alert alert-danger">{this.state.error}</p>
              </div>
              :
              ''
            }
            <div className="col-md-12">
              <button onClick={this.handleSubmit} type="submit" className="wrf-button" disabled={this.state.isLoading}><span> {this.state.isLoading ? "Te rugam sa astepti...":"REZERVA LOCUL"} </span></button>
            </div>
          </div>
        </div>
      </Fragment>
      
    );
  }
}

export default App;
